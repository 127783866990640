import httpHelper from '../../../../utils/httpHelper';
import { appConfig } from '../../../../constants/appConfig';

const service = {
  async getRoutes(payload) {
    const endPoint = `/routing/optimizeRoute`;
    const result = await httpHelper.makeGetRequest(
      appConfig.baseUrl,
      endPoint,
      payload,
      // 'Bearer',
    );
    return result;
  },
  async getRouteHistory(payload) {
    const endPoint = `/routing/findAll`;
    const result = await httpHelper.makeGetRequest(
      appConfig.baseUrl,
      endPoint,
      payload,
      // 'Bearer',
    );
    return result;
  },
  async getAllShifts(payload) {
    const endPoint = `/shift/findAll`;
    const result = await httpHelper.makeGetRequest(
      appConfig.baseUrl,
      endPoint,
      payload,
      // 'Bearer',
    );
    return result;
  },
  async getAllResponse(payload) {
    const endPoint = `response/findAll`;
    const result = await httpHelper.makeGetRequest(
      appConfig.baseUrl,
      endPoint,
      payload,
      // 'Bearer',
    );
    return result;
  },
  async findByRequestId(payload) {
    const endPoint = `transportation/findByRequestId`;
    const result = await httpHelper.makeGetRequest(
      appConfig.baseUrl,
      endPoint,
      payload,
      // 'Bearer',
    );
    return result;
  },
  async getAllSchema(payload) {
    const endPoint = `/schema/findAll`;
    const result = await httpHelper.makeGetRequest(
      appConfig.baseUrl,
      endPoint,
      payload,
      // 'Bearer',
    );
    return result;
  },
  async getAllTasks(payload) {
    const endPoint = `/task/findAll`;
    const result = await httpHelper.makeGetRequest(
      appConfig.baseUrl,
      endPoint,
      payload,
      // 'Bearer',
    );
    return result;
  },
  async postUserSecondCoordinate(payload) {
    const endPoint = `/user/updateUserCoordinate`;
    const result = await httpHelper.makePostRequest(
      appConfig.baseUrl,
      endPoint,
      payload,
      // 'Bearer',
    );
    return result;
  },
};

export default service;
