import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Pagination, CircularProgress, Button } from '@mui/material';
import '../styles.css';
import { TextField, Autocomplete } from '@mui/material';
import 'moment/locale/tr'; // Import the Turkish locale
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from 'react-leaflet';
import * as DEFAULT from '../constants/list.default';
import service from '../service';
import moment from 'moment';
import busStop from '../../../../assets/images/bus-stop.svg';
import startIconMarker from '../../../../assets/images/start-Icon.svg';
import finishIconMarker from '../../../../assets/images/finish-Icon.svg';
import homeIconMarker from '../../../../assets/images/home-Icon.svg';
import dragedIconMarker from '../../../../assets/images/draged-Icon.svg';
import alplaIconMarker from '../../../../assets/images/alpla-Icon.svg';
import { toast } from 'react-toastify';
const Newroute = () => {
  const busStopIcon = new L.Icon({
    iconUrl: busStop,
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });
  const startIcon = new L.Icon({
    iconUrl: startIconMarker,
    iconSize: [40, 40],
    iconAnchor: [15, 30],
  });
  const finishIcon = new L.Icon({
    iconUrl: finishIconMarker,
    iconSize: [40, 40],
    iconAnchor: [15, 30],
  });
  const homeIcon = new L.Icon({
    iconUrl: homeIconMarker,
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });
  const dragedIcon = new L.Icon({
    iconUrl: dragedIconMarker,
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });
  const alplaIcon = new L.Icon({
    iconUrl: alplaIconMarker,
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });
  const location = useLocation();

  const [sortModel, setSortModel] = useState(DEFAULT.SORT_MODEL);
  const [isLoading, setIsLoading] = useState(DEFAULT.IS_LOADING);
  const [isLoadingOnClick, setIsLoadingOnClick] = useState(
    DEFAULT.IS_LOADINGONCLICK,
  );
  const [page, setPage] = useState(DEFAULT.PAGE);
  const [routes, setRoutes] = useState(DEFAULT.ROUTES);
  const [shifts, setShifts] = useState(DEFAULT.SHIFTS);
  const [count, setCount] = useState(DEFAULT.COUNT);
  const [vehicleId, setVehicleId] = useState(DEFAULT.VEHICLEID);
  const [shiftId, setShiftId] = useState(DEFAULT.SHIFTID);
  const [schema, setSchema] = useState(DEFAULT.SCHEMA);
  const [points, setPoints] = useState(DEFAULT.POINTS);
  const [markers, setMarkers] = useState(DEFAULT.MARKERS);
  const [startCoordinates, setStartCoordinates] = useState(
    DEFAULT.STARTCOORDINATES,
  );
  const [distance, setDistance] = useState(DEFAULT.DISTANCE);
  const [duration, setDuration] = useState(DEFAULT.DURATION);
  const [draggedPersonelData, setDraggedPersonelData] = useState(
    DEFAULT.DRAGGEDPERSONELDATA,
  );
  const [tasks, setTasks] = useState(DEFAULT.TASKS);
  const [selectedTask, setSelectedTask] = useState(DEFAULT.SELECTEDTASK);
  const [selectedTaskPoints, setSelectedTaskPoints] = useState(
    DEFAULT.SELECTEDTASKPOINTS,
  );
  const [selectedTaskMarkers, setSelectedTaskMarkers] = useState(
    DEFAULT.SELECTEDTASKMARKERS,
  );
  const [selectedTaskDistance, setSelectedTaskDistance] = useState(
    DEFAULT.SELECTEDTASKDISTANCE,
  );

  const [emlpoyees, setEmlpoyees] = useState(DEFAULT.EMPLOYEESDATA);
  const [selectedTaskStartTime, setSelectedTaskStartTime] = useState(
    DEFAULT.SELECTEDTASKSTARTTIME,
  );
  const [selectedTaskEndTime, setSelectedTaskEndTime] = useState(
    DEFAULT.SELECTEDTASKENDTIME,
  );
  const [homeRoutePoints, setHomeRoutePoints] = useState(
    DEFAULT.HOMEROUTEPOINTS,
  );
  const [homeRouteDistance, setHomeRouteDistance] = useState(
    DEFAULT.HOMEROUTEDISTANCE,
  );
  const [homeRouteDuration, setHomeRouteDuration] = useState(
    DEFAULT.HOMEROUTEDURATION,
  );
  const [endCoordinate, setEndCoordinate] = useState(null);

  // content.map((item, index) => {
  //   console.log(JSON.parse(item.taskData));
  // })

  const paginationChange = (_, e_page) => setPage(e_page - 1);
  const onClickCreateRote = async () => {
    setIsLoadingOnClick(true);
    try {
      if (endCoordinate === null) {
        toast.error('Bitiş noktası koordinatı boş olamaz');
        setIsLoadingOnClick(false);
        return;
      }
      const result = await service.getRoutes({
        vehicleId: vehicleId,
        shiftId: shiftId,
        routePoint: 1,
        endCoordinate: endCoordinate,
      });
      const data = result?.data || [];
      const routingResultData = JSON.parse(data?.routingResultData);
      setDistance(routingResultData.solution.distance);
      setDuration(routingResultData.solution.transport_time);
      const emlpoyeeData = JSON.parse(data.routingRequestData);
      const serviceMarkers = emlpoyeeData?.services;
      const employeeDataVehicles = emlpoyeeData?.vehicles[0];
      setMarkers(serviceMarkers);
      setEmlpoyees(data.users);
      setStartCoordinates(employeeDataVehicles.start_address);
      const points = routingResultData?.solution?.routes[0].points;
      setPoints(points);
      setRoutes(data);
      const resultHomeRoute = await service.getRoutes({
        vehicleId: vehicleId,
        shiftId: shiftId,
        routePoint: 0,
        endCoordinate: endCoordinate,
      });
      const dataHomeRoute = resultHomeRoute?.data || [];
      const routingResultDataHomeRoute = JSON.parse(
        dataHomeRoute?.routingResultData,
      );
      const routingResultHomeDistance =
        routingResultDataHomeRoute?.solution?.distance;
      const routingResultHomeDuration =
        routingResultDataHomeRoute?.solution?.transport_time;
      setHomeRouteDistance(routingResultHomeDistance);
      setHomeRouteDuration(routingResultHomeDuration);
      const pointsHomeRoute =
        routingResultDataHomeRoute?.solution?.routes[0].points;
      setHomeRoutePoints(pointsHomeRoute);
    } catch (error) {
      toast.error(
        'Gerekli seçimleri yaptığınızdan ve inputları doldurduğunuzdan emin olunuz',
      );
    }
    setIsLoadingOnClick(false);
  };
  const handelCalculateDistance = async (
    userId,
    userName,
    userSurname,
    markerLatitude,
    markerLongitude,
    userFirstCoordinateLat,
    userFirstCoordinateLon,
  ) => {
    const markerPosition = [markerLatitude, markerLongitude];
    const api = 'https://graphhopper.com/api/1/route?';

    if (userFirstCoordinateLat && userFirstCoordinateLon) {
      // Personelin mevcut ve yeni koordinatlarını al
      setEmlpoyees((prevState) => {
        // prevState = eski state
        const newState = [...prevState]; // Yeni bir dizi oluştur
        const index = newState.findIndex((item) => item.userId === userId); // Kullanıcının indexini bul
        newState[index].userCoordinate2 =
          `${markerPosition[0]}, ${markerPosition[1]}`; // Kullanıcının yeni koordinatını güncelle
        return newState; // Yeni state'i döndür
      });

      //ac7e9130-23d7-4b7d-a805-3c495be16ba9
      // API isteği için gereken parametreleri oluştur
      const queryString = `point=${userFirstCoordinateLat},${userFirstCoordinateLon}&point=${markerPosition[0]},${markerPosition[1]}&profile=foot&layer=Omniscale&points_encoded=false&key=2513ea31-bc2e-4d19-b6aa-3aee9cdc070f`;

      // API isteğini gerçekleştir
      const response = await fetch(`${api}${queryString}`);
      const data = await response.json();

      // Kontrol et, eğer başarılı ise mesafeyi al
      if (response.ok) {
        const personFootPoints = data.paths[0].points.coordinates;
        const distance = data.paths[0].distance;
        setDraggedPersonelData({
          id: userId,
          distance: distance,
          name: userName + ' ' + userSurname,
          points: personFootPoints,
        });
        const post = await service.postUserSecondCoordinate({
          users: [
            {
              userId: userId,
              newCoordinate: {
                coordinate: `${markerPosition[0]}, ${markerPosition[1]}`,
              },
            },
          ],
        });
        post;
        return distance; // You can return the distance or use it as needed
      } else {
        console.error('API isteği başarısız:', data.message);
        return null;
      }
    }
  };
  const getData = async () => {
    setIsLoading(true);
    const { field, sort } =
      sortModel.length > 0 ? sortModel[0] : { field: 'taskId', sort: 'desc' };
    const payload = {
      // ...filterData,
      size: 10,
      page: page,
      // sort: `${field},${sort}`,
    };
    const result = await service.getAllShifts(payload);
    const resultSchema = await service.getAllSchema(payload);
    const resultTasks = await service.getAllTasks({
      ...payload,
      size: 100000,
    });
    const data = result?.data?.content || [];
    const schemaData = resultSchema?.data?.content || [];
    const tasksData = resultTasks?.data?.content || [];
    setTasks(tasksData);
    const properties = result?.data;
    setCount(properties?.totalPages);
    setShifts(data);
    setSchema(schemaData);
    setIsLoading(false);
  };
  const handleSelectTask = (event) => {
    const taskRoute = JSON.parse(event.taskRoute);
    const taskDatas = JSON.parse(event.taskData);
    const taskRoutePoints = taskRoute.paths[0];
    const taskRouteDistance = taskRoutePoints.distance;
    setSelectedTaskDistance({
      distance: (taskRouteDistance / 1000).toFixed(2),
      plate: taskDatas.service.plate,
      driver: taskDatas.service.title,
    });
    setSelectedTaskPoints(taskRoutePoints.points);
    setSelectedTaskMarkers(taskDatas);
    setSelectedTask(event.taskRoute);
    setSelectedTaskStartTime(taskDatas.startTime);
    setSelectedTaskEndTime(taskDatas.endTime);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="p-3 flex flex-col justify-center items-center font-[Inter]">
      <div className="w-max max-lg:w-full p-3">
        <div className=" py-3 px-2 mx-3 flex flex-wrap justify-between max-sm:justify-center gap-3 items-center">
          <span className="text-lg sm:text-2xl max-sm:text-center">
            Rotalar
          </span>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center w-[890px]">
            <CircularProgress className="users-list-circular-progress" />
          </div>
        ) : (
          <>
            <div className="bg-white rounded-xl shadow-xl p-3 m-3 w-[984px] max-lg:w-full">
              <div className="flex flex-col gap-4 justify-between items-center">
                <Autocomplete
                  id="combo-box-demo-service"
                  size="small"
                  options={schema}
                  getOptionLabel={(option) => option.schemaTitle}
                  getOptionSelected={(option, value) =>
                    option.schemaId === value.schemaId
                  }
                  onChange={(event, value) => {
                    if (value) {
                      setVehicleId(value.schemaId);
                    } else {
                      // Kullanıcı bir öğe seçmediğinde yapılacak işlemler
                    }
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="Servis Seçiniz *" />
                  )}
                />
                <Autocomplete
                  id="combo-box-demo-shift"
                  options={shifts}
                  size="small"
                  getOptionLabel={(option) => option.shiftTitle}
                  getOptionSelected={(option, value) =>
                    option.shiftId === value.shiftId
                  }
                  onChange={(event, value) => {
                    if (value) {
                      setShiftId(value.shiftId);
                    } else {
                      // Kullanıcı bir öğe seçmediğinde yapılacak işlemler
                    }
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="Vardiya seçiniz *" />
                  )}
                />

                <TextField
                  label="Bitiş noktası koordinatı *"
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    setEndCoordinate(e.target.value);
                  }}
                />
                <Button
                  onClick={onClickCreateRote}
                  variant="outlined"
                  size="small"
                  fullWidth
                >
                  ROTALAMA OLUŞTUR
                </Button>
                <Autocomplete
                  id="combo-box-demo-task"
                  size="small"
                  options={tasks}
                  getOptionLabel={(option) => {
                    const index = tasks.indexOf(option) + 1;
                    return (
                      index +
                      ' | ' +
                      JSON.parse(option.taskData).service.title +
                      ' | ' +
                      JSON.parse(option.taskData).service.plate +
                      ' | ' +
                      (JSON.parse(option.taskData).task === 'entry'
                        ? 'TOPLAMA'
                        : 'DAĞITIM') +
                      ' | ' +
                      moment(JSON.parse(option.taskData).startTime).format(
                        'DD.MM.YYYY HH:mm',
                      )
                    );
                  }}
                  getOptionSelected={(option, value) =>
                    option.taskId === value.taskId
                  }
                  onChange={(event, value) => {
                    if (value) {
                      handleSelectTask(value);
                    } else {
                      // Kullanıcı bir öğe seçmediğinde yapılacak işlemler
                    }
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Karşılaştırma rotası seçiniz"
                    />
                  )}
                />
              </div>
              {/*  <TDataGrid
                rows={tasks}
                columns={columns}
                sortModel={sortModel}
                setSortModel={setSortModel}
                checkBoxSelection={true}
              />
              <div className="flex justify-center items-center p-3 m-3">
                <Pagination
                  page={page + 1}
                  onChange={paginationChange}
                  count={count}
                  color="primary"
                  size="small"
                />
              </div>
              */}
            </div>
            {isLoading && (
              <div className="flex justify-center items-center w-[890px]">
                <CircularProgress className="users-list-circular-progress" />
              </div>
            )}
            {homeRouteDistance !== 0 && (
              <div className="bg-white rounded-xl flex flex-col gap-4 justify-center lg:justify-around shadow-xl p-3 m-3 w-[984px] max-lg:w-full">
                <span className="flex text-[#2BAAE2] justify-center font-[Inter] items-center">
                  PERSONEL EVLERİ ÜZERİNDEN OLUŞTURULMUŞ ROTA DETAYLARI
                </span>
                <div className="flex flex-wrap gap-4 justify-center lg:justify-around">
                  <span className="flex justify-center items-center font-[Inter]">
                    {'  Tahmini Mesafe : ' +
                      (homeRouteDistance / 1000).toFixed(2) +
                      ' km'}
                  </span>

                  <span className="flex justify-center items-center font-[Inter]">
                    {'  Tahmini Süre : ' +
                      (homeRouteDuration / 60).toFixed(0) +
                      ' dk'}
                  </span>
                </div>
              </div>
            )}
            {distance !== 0 && (
              <div className="bg-white rounded-xl flex flex-col gap-4 justify-center lg:justify-around shadow-xl p-3 m-3 w-[984px] max-lg:w-full">
                <span className="flex text-[#2BAAE2] font-[Inter] justify-center items-center">
                  PERSONEL YÜRÜME MESAFELERİ ÜZERİNDEN OLUŞTURULMUŞ ROTA
                  DETAYLARI
                </span>
                <div className="flex flex-wrap gap-4 justify-center lg:justify-around">
                  {draggedPersonelData !== null && (
                    <span className="flex justify-center items-center font-[Inter]">
                      {draggedPersonelData.name +
                        ' ' +
                        draggedPersonelData.distance.toFixed(2) +
                        ' metre yürüme mesafesi'}
                    </span>
                  )}
                  <span className="flex justify-center items-center font-[Inter]">
                    {'  Tahmini Mesafe : ' +
                      (distance / 1000).toFixed(2) +
                      ' km'}
                  </span>

                  <span className="flex justify-center items-center font-[Inter]">
                    {'  Tahmini Süre : ' + (duration / 60).toFixed(0) + ' dk'}
                  </span>
                </div>
              </div>
            )}
            {selectedTaskDistance && selectedTaskDistance.distance && (
              <div className="bg-white rounded-xl flex flex-col gap-4 justify-center lg:justify-around shadow-xl p-3 m-3 w-[984px] max-lg:w-full">
                <span className="flex text-[#2BAAE2] justify-center items-center font-[Inter]">
                  YAPILAN GÖREV DETAYLARI
                </span>
                <div className="flex flex-wrap gap-4 justify-center lg:justify-around">
                  <span className="flex justify-center items-center font-[Inter]">
                    {'Plaka : ' + selectedTaskDistance.plate}
                  </span>
                  <span className="flex justify-center items-center font-[Inter]">
                    {'Sürücü : ' + selectedTaskDistance.driver}
                  </span>
                  <span className="flex justify-center items-center font-[Inter]">
                    {'  Tahmini Mesafe : ' +
                      selectedTaskDistance.distance +
                      ' km'}
                  </span>
                  <span className="flex justify-center items-center font-[Inter]">
                    {'  Tahmini Süre : ' +
                      (
                        (new Date(selectedTaskEndTime) -
                          new Date(selectedTaskStartTime)) /
                        (1000 * 60)
                      ).toFixed(0) +
                      ' dk'}
                  </span>
                </div>
              </div>
            )}
            {
              <div
                id="map"
                className="bg-white rounded-xl shadow-xl flex flex-col gap-4  m-3 w-[984px] max-lg:w-full"
              >
                <MapContainer
                  attributionControl={false}
                  center={[37.941863444539074, 32.54820654710296]}
                  zoom={12}
                  scrollWheelZoom={true}
                  className="rounded-xl"
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                  />
                  {points &&
                    points.map((item, index) => (
                      <React.Fragment key={'Points_' + index}>
                        <Polyline
                          pathOptions={{ color: 'black', weight: 5 }}
                          positions={item.coordinates.map((coord) => [
                            coord[1],
                            coord[0],
                          ])}
                        />
                        <Polyline
                          pathOptions={{ color: '#6EB4F5', weight: 4 }}
                          positions={item.coordinates.map((coord) => [
                            coord[1],
                            coord[0],
                          ])}
                        />
                      </React.Fragment>
                    ))}
                  {homeRoutePoints &&
                    homeRoutePoints.map((item, index) => (
                      <React.Fragment key={'Points_' + index}>
                        <Polyline
                          pathOptions={{ color: 'black', weight: 3 }}
                          positions={item.coordinates.map((coord) => [
                            coord[1],
                            coord[0],
                          ])}
                        />
                        <Polyline
                          pathOptions={{ color: '#F3A505', weight: 2 }}
                          positions={item.coordinates.map((coord) => [
                            coord[1],
                            coord[0],
                          ])}
                        />
                      </React.Fragment>
                    ))}

                  {selectedTaskPoints !== null &&
                  Array.isArray(selectedTaskPoints.coordinates) &&
                  selectedTaskPoints.coordinates.length > 0 ? (
                    <React.Fragment key={'selectedTaskPoints'}>
                      <Polyline
                        pathOptions={{ color: 'black', weight: 5 }}
                        positions={selectedTaskPoints.coordinates.map(
                          (point) => [point[1], point[0]],
                        )}
                      />
                      <Polyline
                        pathOptions={{ color: '#05D7A0', weight: 4 }}
                        positions={selectedTaskPoints.coordinates.map(
                          (point) => [point[1], point[0]],
                        )}
                      />
                    </React.Fragment>
                  ) : null}
                  {draggedPersonelData !== null &&
                  Array.isArray(draggedPersonelData.points) &&
                  draggedPersonelData.points.length > 0 ? (
                    <React.Fragment key={'draggedPersonelData_'}>
                      <Polyline
                        pathOptions={{ color: 'black', weight: 10 }}
                        positions={draggedPersonelData.points.map((point) => [
                          point[1],
                          point[0],
                        ])}
                      />
                      <Polyline
                        pathOptions={{ color: 'yellow' }}
                        positions={draggedPersonelData.points.map((point) => [
                          point[1],
                          point[0],
                        ])}
                      />
                    </React.Fragment>
                  ) : null}
                  {startCoordinates &&
                    startCoordinates.lat &&
                    startCoordinates.lon && (
                      <React.Fragment key={'startCoordinates'}>
                        <Marker
                          icon={alplaIcon}
                          position={[
                            startCoordinates.lat,
                            startCoordinates.lon,
                          ]}
                        >
                          <Popup>
                            <span className={`font-[Inter] text-gray-500`}>
                              Başlangıç Noktası
                            </span>
                          </Popup>
                        </Marker>
                      </React.Fragment>
                    )}
                  {selectedTaskMarkers &&
                    selectedTaskMarkers.personnelLocations &&
                    selectedTaskMarkers.personnelLocations.length > 0 && (
                      <>
                        {selectedTaskMarkers.personnelLocations.map(
                          (location, locationIndex) => {
                            const coordinate = location.coordinate.split(',');
                            return (
                              <React.Fragment
                                key={`locationS_${locationIndex}`}
                              >
                                <Marker
                                  icon={busStopIcon}
                                  key={`locationS_${locationIndex}`}
                                  position={[
                                    parseFloat(coordinate[0]),
                                    parseFloat(coordinate[1]),
                                  ]}
                                >
                                  <Popup>
                                    <span
                                      className={`font-[Inter] text-gray-500`}
                                    >
                                      Saat : {location.time}
                                    </span>{' '}
                                    <br />
                                    <span className={` text-[#46ACDC]`}>
                                      Durak : {locationIndex + 1}
                                    </span>{' '}
                                    <br />
                                    <span
                                      className={`font-[Inter] text-gray-500`}
                                    >
                                      Koordinat : {location.coordinate}
                                    </span>
                                  </Popup>
                                </Marker>
                                <Marker
                                  icon={startIcon}
                                  key="startCoordinate"
                                  position={[
                                    parseFloat(
                                      selectedTaskMarkers.startCoordinate
                                        .replace(/\[|\]/g, '')
                                        .split(',')[0],
                                    ),
                                    parseFloat(
                                      selectedTaskMarkers.startCoordinate
                                        .replace(/\[|\]/g, '')
                                        .split(',')[1],
                                    ),
                                  ]}
                                >
                                  <Popup>
                                    <span className="text-green-600 font-[Inter]">
                                      Başlangıç
                                    </span>{' '}
                                    <br />
                                    <span className="font-[Inter]">
                                      Koordinat :{' '}
                                      {selectedTaskMarkers.startCoordinate.replace(
                                        /\[|\]/g,
                                        '',
                                      )}
                                    </span>
                                    <br />
                                    <span className="font-[Inter]">
                                      Tarih :{' '}
                                      {moment(selectedTaskMarkers.startTime)
                                        .locale('tr')
                                        .format('llll')}
                                    </span>{' '}
                                  </Popup>
                                </Marker>
                                <Marker
                                  icon={finishIcon}
                                  key="endCoordinate"
                                  position={[
                                    parseFloat(
                                      selectedTaskMarkers.endCoordinate
                                        .replace(/\[|\]/g, '')
                                        .split(',')[0],
                                    ),
                                    parseFloat(
                                      selectedTaskMarkers.endCoordinate
                                        .replace(/\[|\]/g, '')
                                        .split(',')[1],
                                    ),
                                  ]}
                                >
                                  <Popup>
                                    <span className="font-[Inter] text-red-600">
                                      Bitiş
                                    </span>{' '}
                                    <br />
                                    <span>
                                      Koordinat :{' '}
                                      {selectedTaskMarkers.endCoordinate.replace(
                                        /\[|\]/g,
                                        '',
                                      )}
                                    </span>
                                    <br />
                                    <span className="font-[Inter]">
                                      Tarih :{' '}
                                      {moment(selectedTaskMarkers.endTime)
                                        .locale('tr')
                                        .format('llll')}
                                    </span>{' '}
                                  </Popup>
                                </Marker>
                              </React.Fragment>
                            );
                          },
                        )}
                      </>
                    )}
                  {emlpoyees &&
                    emlpoyees.map((location, locationIndex) => {
                      const userFirstCoordinate = location.userCoordinate;
                      const userSecondCoordinate = location.userCoordinate2
                        ? location.userCoordinate2
                        : location.userCoordinate;
                      const firstCoordinateLat =
                        userFirstCoordinate.split(',')[0];
                      const firstCoordinateLon =
                        userFirstCoordinate.split(',')[1];
                      const secondCoordinateLat =
                        userSecondCoordinate.split(',')[0];
                      const secondCoordinateLon =
                        userSecondCoordinate.split(',')[1];
                      return (
                        <React.Fragment
                          key={`Fragment_${locationIndex}_${location.userId}`}
                        >
                          {/* Non-draggable marker */}
                          <Marker
                            icon={homeIcon}
                            position={[firstCoordinateLat, firstCoordinateLon]}
                            key={`nonDraggable_${locationIndex}_${location.userId}`}
                          >
                            <Popup>
                              <span className={`text-gray-500 font-[Inter]`}>
                                ID: {location.userId}
                                <br />
                                İSİM: {location.userName}
                                <br />
                                SOYİSİM: {location.userSurname}{' '}
                              </span>
                            </Popup>
                          </Marker>

                          {/* Draggable marker */}
                          <Marker
                            draggable={true}
                            icon={dragedIcon}
                            key={`draggable_${locationIndex}_${location.userId}`}
                            position={[
                              secondCoordinateLat,
                              secondCoordinateLon,
                            ]}
                            zIndexOffset={100}
                            eventHandlers={{
                              dragend: (e) => {
                                const markerPosition = e.target.getLatLng();
                                handelCalculateDistance(
                                  location.userId,
                                  location.userName,
                                  location.userSurname,
                                  markerPosition.lat,
                                  markerPosition.lng,
                                  firstCoordinateLat,
                                  firstCoordinateLon,
                                );
                              },
                            }}
                          >
                            <Popup>
                              <span className={`font-[Inter] text-gray-500`}>
                                ID: {location.userId}
                                <br />
                                İSİM: {location.userName}
                                <br />
                                SOYİSİM: {location.userSurname}
                              </span>
                            </Popup>
                          </Marker>
                        </React.Fragment>
                      );
                    })}
                </MapContainer>
              </div>
            }
          </>
        )}
      </div>
    </div>
  );
};
export default Newroute;
