import React from 'react';

import Calendar from '../Calendar';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '../langSwitcher';

import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { IconButton, Drawer, Slide } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

import zIndex from '@mui/material/styles/zIndex';
import { useBoundStore } from '../../stores';

import AccountCircle from '@mui/icons-material/AccountCircle';

function TDrawer({
  open,
  handleDrawerClose,
  handleDrawerOpen,
  logoutMerchant,
  anchor,
  children,
}) {
  const theme = useTheme();
  const textPool = useBoundStore((state) => state.textPool);
  // console.log(useBoundStore((state) => state));

  return (
    <>
      <Drawer
        TransitionComponent={Slide}
        transitionDuration={400}
        sx={{
          '& .MuiDrawer-paper': {
            width: '25%',
            boxSizing: 'border-box',
            backgroundColor: '#F3F4F6',
            border: 'none',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            // [theme.breakpoints.between('md', 'xl')]: {
            //   display: 'none',
            // },
            [theme.breakpoints.down('md')]: {
              width: '100%',
            },
          },
        }}
        open={open}
        variant="persistent"
        anchor={anchor}
      >
        <div className="p-3">
          <div
            className={`flex justify-between items-center ${anchor === 'right' ? 'flex-row' : 'flex-row-reverse'
              }`}
          >
            <IconButton onClick={handleDrawerClose}>
              {anchor === 'left' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>

            <div
              className={` flex justify-end items-center gap-5 ${anchor === 'right' ? 'flex-row' : 'flex-row-reverse'
                }`}
            >
              {/* <IconButton>
                <EmailIcon />
              </IconButton>
              <IconButton>
                <NotificationsIcon />
              </IconButton> */}
              {/* <LanguageIcon /> */}
              <div className="flex flex-row ">
                <IconButton>
                  <AccountCircle fontSize="large" />
                </IconButton>
                <div className="flex justify-center items-center w-24 ">
                  <span className="font-bold text-[12px] ">
                    {JSON.parse(localStorage.getItem('user')).user.id}
                  </span>
                </div>
              </div>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                sx={{ ...(open && { display: 'none' }) }}
              >
                <MenuIcon />
              </IconButton>
            </div>
          </div>

          <Button
            onClick={() => {
              logoutMerchant();
            }}
            variant="outlined"
            className="w-full mt-5 z-[0]"
          >
            {/* {textPool['ÇIKIŞ YAP']} */}
            ÇIKIŞ YAP
          </Button>

          <div className="w-full">{children}</div>
        </div>
      </Drawer>
    </>
  );
}

export default TDrawer;
