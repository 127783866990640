export const columns = [
  {
    field: 'shiftId',
    headerName: 'Vardiya ID',
    width: 200,
    renderCell: (data) => {
      return <span>{data.row.shiftId}</span>;
    },
  },{
    field: 'shiftTitle',
    headerName: 'Vardiya Başlığı',
    width: 400,
    renderCell: (data) => {
      return <span>{data.row.shiftTitle}</span>;
    },
  },
  /* {
    field: 'actions',
    headerName: 'İşlem',
    width: 60,
    sortable: false,
    renderCell: (data) => {
      const onClick = () => {
        const parsedData = data.row.taskRoute);
        const distance = parsedData.paths[0].distance / 1000;
        const points = parsedData.paths[0].points.coordinates;
        const selectedTaskdata = data.row.taskData);
        data.handleClickTask(distance, points, selectedTaskdata);
      };

      return (
        <div className="flex justify-center items-center">
          <IconButton onClick={onClick} href={`#map`}>
            <LocationOnIcon />
          </IconButton>
        </div>
      );
    },
  },*/
];

export const filterList = [
  { value: 'id', text: 'Kullanıcı ID' },
  { value: 'firstname', text: 'İsim' },
  { value: 'lastname', text: 'Soyisim' },
  { value: 'email', text: 'Email' },
  { value: 'gsm', text: 'Telefon' },
];
