import { filterList } from './table_columns_data';

export const FILTER_DATA = filterList.reduce((accumulator, item) => {
  const newAccumulator = { ...accumulator };
  newAccumulator[item.value] = '';
  return newAccumulator;
}, {});

export const COUNT = 0;
export const VEHICLESIZE = 0;
export const SHIFTID = 0;
export const MINSIZE = 0;
export const MAXSIZE = 0;
export const IS_LOADING = false;
export const PAGE = 0;
export const TASKS = [];
export const SORT_MODEL = [];
export const DISTRIBUTION = [];
export const DISTRIBUTIONEMLPOYEE = [];
export const MERGEDDATA = [];
export const VEHICLES = [];
export const PERSONELADRESSTYPE = null;
