import {
  EnterLayoutPage,
  LoginPage,
  ErrorPage,
  Shifts,
  Products,
  Users,
  UploadEmployee,
} from '../pages';
import Routehistory from '../pages/definitions/Routes/Routehistory';
import Newroute from '../pages/definitions/Routes/Newroute';
import Activeroute from '../pages/definitions/Routes/Activeroute';

const routes = [
  {
    path: '/',
    element: <Products />,
  },
  {
    path: '/login',
    element: (
      <EnterLayoutPage>
        <LoginPage />
      </EnterLayoutPage>
    ),
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
  {
    path: '/users/employees',
    element: <Users />,
  },
  {
    path: '/users/employees/upload',
    element: <UploadEmployee />,
  },
  {
    path: '/users/shifts',
    element: <Shifts />,
  },
  {
    path: '/users/routes/newroute',
    element: <Newroute />,
  },
  {
    path: '/users/routes/history',
    element: <Routehistory />,
  },
  {
    path: '/users/routes/activeroute',
    element: <Activeroute />,
  },
];

export default routes;
