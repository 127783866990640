import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Pagination, CircularProgress, Button } from '@mui/material';
import '../styles.css';
import { TextField, Autocomplete } from '@mui/material';
import 'moment/locale/tr'; // Import the Turkish locale
import {
  withLeaflet,
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from 'react-leaflet';
import * as DEFAULT from '../constants/list.default';
import service from '../service';
import { Calculate } from '@mui/icons-material';

const Activeroute = () => {
  const location = useLocation();

  const [filterData, setFilterData] = useState({ query: '' });
  const [sortModel, setSortModel] = useState(DEFAULT.SORT_MODEL);
  const [isLoading, setIsLoading] = useState(DEFAULT.IS_LOADING);
  const [page, setPage] = useState(DEFAULT.PAGE);
  const [shifts, setShifts] = useState(DEFAULT.SHIFTS);
  const [points, setPoints] = useState(DEFAULT.POINTS);
  const [personelData, setPersonelData] = useState(DEFAULT.PERSONELDATA);
  const [distance, setDistance] = useState(DEFAULT.DISTANCE);
  const [draggedPersonelData, setDraggedPersonelData] = useState(
    DEFAULT.DRAGGEDPERSONELDATA,
  );
  const [positionSecond, setPositionSecond] = useState([]);

  // TIKLANAN İKİ NOKTA ARASINI HESAPLAMA BAŞLANGIÇ

  // TIKLANAN İKİ NOKTA ARASINI HESAPLAMA BİTİŞ
  const paginationChange = (_, e_page) => setPage(e_page - 1);
  const handleCalculateDistance = async (
    userIdentity,
    markerLatitude,
    markerLongitude,
    userName,
    userSurname,
  ) => {
    // Marker'ın yeni konumu
    const markerPosition = [markerLatitude, markerLongitude];
    const api = 'https://graphhopper.com/api/1/route?';

    // userIdentity'ye göre personel verisini bul
    const personel = personelData.find(
      (person) => person.userIdentity === userIdentity,
    );

    if (personel) {
      // Personelin mevcut ve yeni koordinatlarını al
      const personelCoordinate = personel.userCoordinate
        .split(',')
        .map(parseFloat);

      //ac7e9130-23d7-4b7d-a805-3c495be16ba9
      // API isteği için gereken parametreleri oluştur
      const queryString = `point=${personelCoordinate[0]},${personelCoordinate[1]}&point=${markerPosition[1]},${markerPosition[0]}&profile=foot&layer=Omniscale&points_encoded=false&key=ac7e9130-23d7-4b7d-a805-3c495be16ba9`;

      // API isteğini gerçekleştir
      const response = await fetch(`${api}${queryString}`);
      const data = await response.json();

      // Kontrol et, eğer başarılı ise mesafeyi al
      if (response.ok) {
        const personFootPoints = data.paths[0].points.coordinates;
        const distance = data.paths[0].distance;
        setDraggedPersonelData({
          id: userIdentity,
          distance: distance,
          name: userName + '  ' + userSurname,
          points: personFootPoints,
        });
        const post = await service.postUserSecondCoordinate({
          users: [
            {
              userId: userIdentity,
              newCoordinate: {
                coordinate: `${markerPosition[1]}, ${markerPosition[0]}`,
              },
            },
          ],
        });
        post;
        return distance; // You can return the distance or use it as needed
      } else {
        console.error('API isteği başarısız:', data.message);
        return null;
      }
    }
  };
  /*
  
  // İki koordinat arasındaki mesafeyi hesaplayan fonksiyon
  const calculateDistance = (point1, point2) => {
    const R = 6371e3; // Dünya yarıçapı (metre cinsinden)
    const φ1 = degToRad(point1[0]);
    const φ2 = degToRad(point2[0]);
    const Δφ = degToRad(point2[0] - point1[0]);
    const Δλ = degToRad(point2[1] - point1[1]);
  
    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
    const distance = R * c;
    return distance;
  };
  
  const degToRad = (deg) => deg * (Math.PI / 180);
  */

  const handleChangeService = async (value) => {
    try {
      const hints = JSON.parse(value.graphhopperRoutingResponseData);
      const payload = {
        requestId: value.graphhopperRoutingRequestId,
      };
      setDraggedPersonelData(null);
      const result = await service.findByRequestId(payload);
      const data = result?.data || [];
      setPersonelData(data);

      if (!hints || !hints.paths || !hints.paths[0] || !hints.paths[0].points) {
        console.error('Invalid data format:', hints);
        // Handle the error or return early if the data is not as expected
        return;
      }

      const paths = hints.paths[0];
      setDistance(paths.distance);

      const coordinates = paths.points.coordinates;

      if (!coordinates || !Array.isArray(coordinates)) {
        console.error('Invalid coordinates format:', coordinates);
        // Handle the error or return early if the coordinates are not as expected
        return;
      }

      const fixedRouteData = coordinates.map((coord) => [coord[1], coord[0]]);
      setPoints(fixedRouteData);
    } catch (error) {
      console.error('Error parsing JSON:', error);
      // Handle the error, e.g., setPoints([]) or display an error message
    }
  };

  const getData = async () => {
    setIsLoading(true);
    const { field, sort } =
      sortModel.length > 0 ? sortModel[0] : { field: 'taskId', sort: 'desc' };
    const payload = {
      // ...filterData,
      size: 10,
      page: page,
      // sort: `${field},${sort}`,
    };
    const result = await service.getAllResponse(payload);
    const data = result?.data?.content || [];
    setShifts(data);
    setIsLoading(false);
  };

  const handleFilterInput = (newValue) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      query: newValue,
    }));
  };

  useEffect(() => {
    setPage(0);
    getData();
  }, [filterData]);

  useEffect(() => {
    getData();
  }, [page, sortModel, location]);

  return (
    <div className="p-3 flex flex-col justify-center items-center">
      <div className="w-max max-lg:w-full p-3">
        <div className=" py-3 px-2 mx-3 flex flex-wrap justify-between max-sm:justify-center gap-3 items-center">
          <span className="text-lg sm:text-2xl max-sm:text-center">
            Rotalar
          </span>
          {/* <div className="mx-2 my-5 flex justify-between items-center">
            <div className="flex ">
              <TextField
                size="small"
                placeholder="Arama"
                onChange={(e) => handleFilterInput(e.target.value)}
                value={filterData.query}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MATERIEL_ICONS.Search />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                  },
                }}
              />
            </div>
          </div> */}
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center w-[890px]">
            <CircularProgress className="users-list-circular-progress" />
          </div>
        ) : (
          <>
            <div className="bg-white rounded-xl shadow-xl p-3 m-3 w-[984px] max-lg:w-full">
              <div className="flex flex-col gap-4 justify-between items-center">
                <Autocomplete
                  id="combo-box-demo"
                  options={shifts}
                  getOptionLabel={(option) => option.graphhopperRoutingTitle}
                  onChange={(event, value) => handleChangeService(value)}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Servis Seçiniz"
                      getOptionSelected={(option, value) =>
                        option.graphhopperRoutingRequestId ===
                        value.graphhopperRoutingRequestId
                      }
                    />
                  )}
                />
              </div>

              {/*  <TDataGrid
                rows={tasks}
                columns={columns}
                sortModel={sortModel}
                setSortModel={setSortModel}
                checkBoxSelection={true}
              />
              <div className="flex justify-center items-center p-3 m-3">
                <Pagination
                  page={page + 1}
                  onChange={paginationChange}
                  count={count}
                  color="primary"
                  size="small"
                />
              </div>
              */}
            </div>
            {distance !== 0 && (
              <div className="bg-white rounded-xl flex flex-wrap  gap-4 justify-center lg:justify-around shadow-xl p-3 m-3 w-[984px] max-lg:w-full">
                {draggedPersonelData !== null && (
                  <span className="flex justify-center items-center">
                    {draggedPersonelData.name +
                      ' ' +
                      draggedPersonelData.distance.toFixed(1) +
                      ' metre yürüme mesafesi'}
                  </span>
                )}
                <span className="flex justify-center items-center">
                  {'  Tahmini Mesafe : ' + (distance / 1000).toFixed(2) + ' km'}
                </span>
              </div>
            )}
            {
              <div
                id="map"
                className="bg-white rounded-xl shadow-xl flex flex-col gap-4 p-3 m-3 w-[984px] max-lg:w-full"
              >
                <MapContainer
                  attributionControl={false}
                  center={[37.941863444539074, 32.54820654710296]}
                  zoom={12}
                  scrollWheelZoom={true}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                  />

                  {points.length > 0 && (
                    <Polyline
                      pathOptions={{ color: 'blue' }}
                      positions={points.map((point) => [point[0], point[1]])}
                    />
                  )}
                  {draggedPersonelData !== null &&
                  Array.isArray(draggedPersonelData.points) &&
                  draggedPersonelData.points.length > 0 ? (
                    <>
                      <Polyline
                        pathOptions={{ color: 'black', weight: 10 }}
                        positions={draggedPersonelData.points.map((point) => [
                          point[1],
                          point[0],
                        ])}
                      />
                      <Polyline
                        pathOptions={{ color: 'yellow' }}
                        positions={draggedPersonelData.points.map((point) => [
                          point[1],
                          point[0],
                        ])}
                      />
                    </>
                  ) : null}

                  <Marker
                    icon={L.icon({
                      iconUrl:
                        'https://cdn2.iconfinder.com/data/icons/IconsLandVistaMapMarkersIconsDemo/256/MapMarker_Marker_Outside_Pink.png',
                      iconSize: [30, 30],
                      iconAnchor: [15, 30],
                    })}
                    position={[37.974411862233374, 32.6422075400363]}
                  >
                    <Popup>
                      <span className={`font-inter text-gray-500 text-center`}>
                        ALPLA PLASTİK
                        <br />
                        KONYA
                      </span>
                    </Popup>
                  </Marker>

                  {personelData &&
                    personelData.map((item, index) => {
                      const userCoordinateString = item.userCoordinate;
                      const userSecondCoordinateString = item.userCoordinate2
                        ? item.userCoordinate2
                        : item.userCoordinate;

                      const [latString, lonString] =
                        userCoordinateString.split(',');
                      const lat = parseFloat(latString);
                      const lon = parseFloat(lonString);

                      const [secondLatString, secondLonString] =
                        userSecondCoordinateString.split(',');
                      const secondLat = parseFloat(secondLatString);
                      const secondLon = parseFloat(secondLonString);

                      const positionFirst = [lat + 0.0001, lon + 0.0001];
                      const positionSecond = [secondLat, secondLon];

                      const customIconFirst = L.icon({
                        iconUrl:
                          'https://cdn2.iconfinder.com/data/icons/IconsLandVistaMapMarkersIconsDemo/256/MapMarker_Marker_Outside_Azure.png',
                        iconSize: [30, 30],
                        iconAnchor: [15, 30],
                      });
                      const customIconSecond = L.icon({
                        iconUrl:
                          'https://cdn2.iconfinder.com/data/icons/IconsLandVistaMapMarkersIconsDemo/256/MapMarker_Marker_Outside_Chartreuse.png',
                        iconSize: [30, 30],
                        iconAnchor: [15, 30],
                      });

                      return (
                        <React.Fragment key={`marker-${index}`}>
                          <Marker
                            key={`first-${index}`}
                            icon={customIconFirst}
                            position={positionFirst}
                          >
                            {/* Popup for the first marker */}
                            <Popup>
                              <span className={`font-inter text-gray-500`}>
                                Personel Adresi
                                <br />
                                {item.userName +
                                  ' ' +
                                  item.userSurname +
                                  ' ' +
                                  item.userIdentity}
                                <br />
                                Adres : {item.userAddress}
                                <br />
                                Koordinat : {item.userCoordinate}
                              </span>
                            </Popup>
                          </Marker>

                          <Marker
                            draggable={true}
                            key={`second-${index}`}
                            icon={customIconSecond}
                            position={positionSecond}
                            eventHandlers={{
                              dragend: (e) => {
                                const markerPosition = e.target.getLatLng();
                                handleCalculateDistance(
                                  item.userIdentity,
                                  markerPosition.lng,
                                  markerPosition.lat,
                                  item.userName,
                                  item.userSurname,
                                );

                                // Update the state with the new position
                                setPositionSecond([
                                  markerPosition.lat,
                                  markerPosition.lng,
                                ]);
                              },
                            }}
                          >
                            {/* Popup for the second marker */}
                            <Popup>
                              <span className={`font-inter text-gray-500`}>
                                İkinci adres
                                <br />
                                {item.userName +
                                  ' ' +
                                  item.userSurname +
                                  ' ' +
                                  item.userIdentity}
                                <br />
                                Koordinat: {positionSecond[0]},{' '}
                                {positionSecond[1]}
                              </span>
                            </Popup>
                          </Marker>
                        </React.Fragment>
                      );
                    })}
                </MapContainer>
              </div>
            }
          </>
        )}
      </div>
    </div>
  );
};

export default Activeroute;
