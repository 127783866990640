import { filterList } from './table_columns_data';

export const FILTER_DATA = filterList.reduce((accumulator, item) => {
  const newAccumulator = { ...accumulator };
  newAccumulator[item.value] = '';
  return newAccumulator;
}, {});

export const COUNT = 0;
export const SORT_MODEL = [];
export const SELECTEDTASK = [];
export const SELECTEDTASKROUTE = [];
export const SELECTEDTASKDISTANCE = 0;
export const SELECTEDTASKPERSONNELSIZE = 0;
export const SELECTEDTASKSTATE = "";
export const SELECTEDTASKDRIVER = "";
export const SELECTEDTASKPLATE = "";
export const TOTALELEMENTS = 0;
export const IS_LOADING = false;
export const PAGE = 0;
export const TASKS = [];
export const TOTALDATA = [];