import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Pagination, CircularProgress, Button } from '@mui/material';
import TDataGrid from '../../../components/Datagrid/Datagrid';
import './styles.css';
import 'moment/locale/tr'; // Import the Turkish locale

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import homeIconMarker from '../../../assets/images/home-Icon.svg';
import { columns } from './constants/table_columns_data';
import * as DEFAULT from './constants/list.default';
import service from './service';
const Users = () => {
  const location = useLocation();

  const [filterData, setFilterData] = useState({ query: '' });
  const [sortModel, setSortModel] = useState(DEFAULT.SORT_MODEL);
  const [isLoading, setIsLoading] = useState(DEFAULT.IS_LOADING);
  const [page, setPage] = useState(DEFAULT.PAGE);
  const [tasks, setTasks] = useState(DEFAULT.TASKS);
  const [count, setCount] = useState(DEFAULT.COUNT);
  const [totalElements, setTotalElements] = useState(DEFAULT.TOTALELEMENTS);
  const [totalData, setTotalData] = useState(DEFAULT.TOTALDATA);
  // content.map((item, index) => {
  //   console.log(JSON.parse(item.taskData));
  // })
  const homeIcon = new L.Icon({
    iconUrl: homeIconMarker,
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });

  const paginationChange = (_, e_page) => setPage(e_page - 1);

  const getData = async () => {
    setIsLoading(true);
    const { field, sort } =
      sortModel.length > 0 ? sortModel[0] : { field: 'taskId', sort: 'desc' };
    const payload = {
      // ...filterData,
      size: 10,
      page: page,
      // sort: `${field},${sort}`,
    };
    const result = await service.getAllUsers(payload);
    setTotalElements(result?.data?.totalElements);
    const data = result?.data?.content || [];
    const properties = result?.data;
    const totalResult = await service.getAllUsers({
      size: properties?.totalElements,
      page: 0,
    });
    const totalData = totalResult?.data?.content || [];
    setTotalData(totalData);
    setCount(properties?.totalPages);
    setTasks(data);
    setIsLoading(false);
  };

  const handleFilterInput = (newValue) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      query: newValue,
    }));
  };

  useEffect(() => {
    setPage(0);
    getData();
  }, [filterData]);

  const filter = async () => {
    setPage(0);
    getData();
  };

  useEffect(() => {
    getData();
  }, [page, sortModel, location]);

  return (
    <div className="p-3 flex flex-col justify-center items-center">
      <div className="w-max max-lg:w-full p-3">
        <div className=" py-3 px-2 mx-3 flex flex-wrap justify-between max-sm:justify-center gap-3 items-center">
          <span className="text-lg sm:text-2xl max-sm:text-center">
            Personeller {totalElements > 0 && `(${totalElements})`}
          </span>
          {/* <div className="mx-2 my-5 flex justify-between items-center">
            <div className="flex ">
              <TextField
                size="small"
                placeholder="Arama"
                onChange={(e) => handleFilterInput(e.target.value)}
                value={filterData.query}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MATERIEL_ICONS.Search />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                  },
                }}
              />
            </div>
          </div> */}
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center w-[890px]">
            <CircularProgress className="users-list-circular-progress" />
          </div>
        ) : (
          <>
            <div className="bg-white rounded-xl shadow-xl p-3 m-3 w-[984px] max-lg:w-full">
              <TDataGrid
                rows={tasks}
                columns={columns}
                sortModel={sortModel}
                setSortModel={setSortModel}
                checkBoxSelection={true}
              />
              <div className="flex justify-center items-center p-3 m-3">
                <Pagination
                  page={page + 1}
                  onChange={paginationChange}
                  count={count}
                  color="primary"
                  size="small"
                />
              </div>
            </div>       
              <div
                id="map"
                className="bg-white rounded-xl shadow-xl flex flex-col gap-4 p-3 m-3 w-[984px] max-lg:w-full"
              >
                <MapContainer
                  attributionControl={false}
                  center={[37.941863444539074, 32.54820654710296]}
                  zoom={12}
                  scrollWheelZoom={true}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                  />
                  {totalData &&
                    totalData.map((item, index) => {
                      // Verilen userCoordinate stringi
                      const userCoordinateString = item.userCoordinate;

                      // Virgül karakterine göre ayırma
                      const [latString, lonString] =
                        userCoordinateString.split(',');

                      // Stringi sayıya çevirme
                      const lat = parseFloat(latString);
                      const lon = parseFloat(lonString);

                      const positionFirst = [lat, lon];
                      const positionSecond = [lat + 0.0001, lon + 0.0001];

                      return (
                        <>
                          <Marker
                            key={index}
                            icon={homeIcon}
                            position={positionFirst}
                          >
                            <Popup>
                              <span className={`font-inter text-gray-500`}>
                                Personel Adresi
                                <br />
                                {item.userName +
                                  ' ' +
                                  item.userSurname +
                                  ' ' +
                                  item.userIdentity}
                              </span>
                            </Popup>
                          </Marker>
                        </>
                      );
                    })}

                  {/*markers &&
                    markers.map((location, locationIndex) => {
                      const { lat, lon, location_id } = location.address;

                      return (
                        <Marker
                          icon={L.icon({
                            iconUrl:
                              'https://cdn2.iconfinder.com/data/icons/IconsLandVistaMapMarkersIconsDemo/256/MapMarker_Marker_Outside_Azure.png',
                            iconSize: [30, 30],
                            iconAnchor: [15, 30],
                          })}
                          key={locationIndex}
                          position={[lat, lon]}
                        >
                          <Popup>
                            <span className={`font-inter text-gray-500`}>
                              PERSONEL: {location_id}
                            </span>
                          </Popup>
                        </Marker>
                      );
                    })*/}
                </MapContainer>
              </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Users;
