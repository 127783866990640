import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Pagination, CircularProgress, Button } from '@mui/material';
import TDataGrid from '../../../components/Datagrid/Datagrid';
import './styles.css';
import MapIcon from '@mui/icons-material/Map';
import { Autocomplete, TextField } from '@mui/material';

import 'moment/locale/tr'; // Import the Turkish locale

// import { CMapContainer } from '../../../components/mapContainer/index';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from 'react-leaflet';

import { columns } from './constants/table_columns_data';
import * as DEFAULT from './constants/list.default';
import service from './service';
import { toast } from 'react-toastify';

const Users = () => {
  const location = useLocation();

  const [filterData, setFilterData] = useState({ query: '' });
  const [sortModel, setSortModel] = useState(DEFAULT.SORT_MODEL);
  const [isLoading, setIsLoading] = useState(DEFAULT.IS_LOADING);
  const [page, setPage] = useState(DEFAULT.PAGE);
  const [shifts, setShifts] = useState(DEFAULT.TASKS);
  const [count, setCount] = useState(DEFAULT.COUNT);
  const [vehicleSize, setVehicleSize] = useState(DEFAULT.VEHICLESIZE);
  const [shiftId, setShiftId] = useState(DEFAULT.SHIFTID);
  const [minSize, setMinSize] = useState(DEFAULT.MINSIZE);
  const [maxSize, setMaxSize] = useState(DEFAULT.MAXSIZE);
  const [distribution, setDistribution] = useState(DEFAULT.DISTRIBUTION);
  const [distributionEmployeeData, setDistributionEmployeeData] = useState(
    DEFAULT.DISTRIBUTIONEMLPOYEE,
  );
  const [mergedData, setMergedData] = useState(DEFAULT.MERGEDDATA);
  const [vehicles, setVehicles] = useState(DEFAULT.VEHICLES);
  const [personelAdressType, setPersonelAdressType] = useState(
    DEFAULT.PERSONELADRESSTYPE,
  );
  // content.map((item, index) => {
  //   console.log(JSON.parse(item.taskData));
  // })

  const paginationChange = (_, e_page) => setPage(e_page - 1);
  // İki JSON verisini birleştirme fonksiyonu
  const mergeJSONData = (distributionsResultData, distributionData) => {
    if (!distributionData || !distributionData.customers) {
      console.error('Hata: distributionData veya customers boş veya tanımsız.');
      return null;
    }

    const mergedData = {
      status: distributionsResultData.status,
      clusters: distributionsResultData.clusters.map((cluster) => {
        const clusterWithCoordinates = {
          ...cluster,
          customers: cluster.ids.map((name) => {
            const userId = parseInt(name.match(/\d+$/)[0], 10);
            const customerInfo = distributionData.customers.find((customer) => {
              const customerId = parseInt(customer.id.match(/\d+$/)[0], 10);
              return customerId === userId;
            });

            if (!customerInfo) {
              console.warn(
                `Uyarı: ${name} için eşleşen kullanıcı bilgisi bulunamadı.`,
              );
              return null;
            }

            return {
              id: customerInfo.id,
              address: customerInfo.address,
              quantity: 1,
            };
          }),
        };

        return clusterWithCoordinates;
      }),
    };

    return mergedData.clusters;
  };
  const onSaveDistribution = async () => {
    setIsLoading(true);

    try {
      const updatedVehicles = [];

      for (let index = 0; index < mergedData.length; index++) {
        const item = mergedData[index];
        const customers = [];

        for (
          let customerIndex = 0;
          customerIndex < item.customers.length;
          customerIndex++
        ) {
          const customer = item.customers[customerIndex];
          const matchResult = customer.id.match(/\d+$/);
          const customerIndexValue = matchResult
            ? parseInt(matchResult[0], 10)
            : null;

          if (customerIndexValue !== null) {
            customers.push(`${customerIndexValue}`);
          } else {
            console.log('Sayısal değer bulunamadı.');
          }
        }

        const vehicle = {
          vehicleId: `${index + 1}`,
          shiftId: shiftId,
          employeeIds: customers,
        };

        updatedVehicles.push(vehicle);
      }

      const endpayload = {
        shiftId: shiftId,
        vehicles: updatedVehicles,
      };
      const result = await service.saveTransportationSchema(endpayload);
      console.log('result:', result);
      setVehicles(updatedVehicles);
    } catch (error) {
      console.error('Error in onClickDistribution:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleChangeService = (id, value) => {
    const employee = id;
    const updateVehicleId = value;
    const updatedData = [...mergedData];

    updatedData.forEach((data, index) => {
      const customerIndex = data.customers.findIndex(
        (customer) => customer.id === employee,
      );
      const idsIndex = data.ids.indexOf(employee);

      if (customerIndex !== -1) {
        // Remove customer from old location
        const removedCustomer = data.customers.splice(customerIndex, 1)[0];

        // Add customer to the new location
        updatedData[value - 1].customers.push(removedCustomer);
      }

      if (idsIndex !== -1) {
        // Remove id from old location
        data.ids.splice(idsIndex, 1);

        // Add id to the new location
        updatedData[value - 1].ids.push(employee);
      }
    });

    // Güncellenmiş veriyi kontrol etmek için
    setMergedData(updatedData);
  };
  const onClickDistribution = async () => {
    setIsLoading(true);
    try {
      setMergedData([]);
      const { field, sort } =
        sortModel.length > 0 ? sortModel[0] : { field: 'taskId', sort: 'desc' };
      const payload = {
        vehicleSize: parseInt(vehicleSize),
        shiftId: shiftId,
        minSize: 0,
        maxSize: 24,
      };

      const result = await service.getDistribution(payload);
      const data = result?.data || [];
      const properties = data;
      // İlk olarak temizlik yapılıyor
      setMergedData([]);
      setDistribution(properties.distributionsResultData);
      setDistributionEmployeeData(properties.distributionData);
      // Daha sonra yeni veri ekleniyor
      setMergedData(
        mergeJSONData(
          JSON.parse(properties.distributionsResultData),
          JSON.parse(properties.distributionData),
        ),
      );
    } catch (error) {
      console.error('Error in onClickDistribution:', error);
      toast.error('Dağıtım oluşturulurken bir hata oluştu.');
      toast.error('Maksimum 80 kişilik dağıtım yapılabilir.');
    } finally {
      setIsLoading(false);
    }
  };

  const getData = async () => {
    setIsLoading(true);
    const { field, sort } =
      sortModel.length > 0 ? sortModel[0] : { field: 'taskId', sort: 'desc' };
    const payload = {
      // ...filterData,
      size: 10,
      page: page,
      // sort: `${field},${sort}`,
    };
    const result = await service.getAllShifts(payload);
    const data = result?.data?.content || [];
    const properties = result?.data;
    setCount(properties?.totalPages);
    setShifts(data);
    setIsLoading(false);
  };

  const handleFilterInput = (newValue) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      query: newValue,
    }));
  };

  useEffect(() => {
    setPage(0);
    getData();
  }, [filterData]);

  const filter = async () => {
    setPage(0);
    getData();
  };

  useEffect(() => {
    getData();
  }, [page, sortModel, location]);

  return (
    <div className="p-3 flex flex-col justify-center items-center">
      <div className="w-screen max-lg:w-full px-2 md:px-20">
        <div className=" py-3 px-2 mx-3 flex flex-wrap justify-between max-sm:justify-center gap-3 items-center">
          <span className="text-lg sm:text-2xl max-sm:text-center">
            Dağıtımlar
          </span>
          {/* <div className="mx-2 my-5 flex justify-between items-center">
            <div className="flex ">
              <TextField
                size="small"
                placeholder="Arama"
                onChange={(e) => handleFilterInput(e.target.value)}
                value={filterData.query}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MATERIEL_ICONS.Search />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                  },
                }}
              />
            </div>
          </div> */}
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center w-[890px]">
            <CircularProgress className="users-list-circular-progress" />
          </div>
        ) : (
          <>
            <div className="bg-white rounded-xl shadow-xl p-3 m-3 w-full max-lg:w-full">
              <div className="flex flex-col gap-4 justify-between items-center">
                <Autocomplete
                  size="small"
                  id="combo-box-demo"
                  options={shifts}
                  getOptionLabel={(option) => option.shiftTitle}
                  getOptionSelected={(option, value) =>
                    option.shiftId === value.shiftId
                  }
                  onChange={(event, value) => {
                    if (value) {
                      setShiftId(value.shiftId);
                    } else {
                      // Kullanıcı bir öğe seçmediğinde yapılacak işlemler
                    }
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="Vardiya seçiniz" />
                  )}
                />

                <Autocomplete
                  size="small"
                  id="combo-box-demo"
                  options={Array.from({ length: 20 }, (_, index) => index + 1)}
                  getOptionLabel={(option) => option.toString()}
                  fullWidth
                  onChange={(event, value) => {
                    if (value) {
                      setVehicleSize(value);
                    } else {
                      // Kullanıcı bir öğe seçmediğinde yapılacak işlemler
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Servis sayısı seçiniz" />
                  )}
                />

                <Button
                  onClick={onClickDistribution}
                  variant="outlined"
                  size="small"
                  fullWidth
                >
                  DAĞITIMI OLUŞTUR
                </Button>
              </div>
              {/*  <TDataGrid
                rows={tasks}
                columns={columns}
                sortModel={sortModel}
                setSortModel={setSortModel}
                checkBoxSelection={true}
              />
              <div className="flex justify-center items-center p-3 m-3">
                <Pagination
                  page={page + 1}
                  onChange={paginationChange}
                  count={count}
                  color="primary"
                  size="small"
                />
              </div>
              */}
            </div>
            {mergedData.length > 0 && (
              <div className="bg-white rounded-xl flex flex-wrap gap-4 justify-center lg:justify-around shadow-xl p-3 m-3 w-full max-lg:w-full overflow-x-auto">
                <div className="flex flex-row gap-2 ">
                  <div
                    className="flex"
                    style={{
                      minWidth: `${((mergedData.length * 1) / 6) * 100}%`,
                      overflowX: 'auto',
                    }}
                  >
                    {mergedData.map((data, dataIndex) => (
                      <div
                        key={dataIndex}
                        className={`w-${Math.floor(
                          (100 * 1) / 6,
                        )}/12 p-2 bg-gray-100 rounded-lg shadow-md`}
                        style={{ marginRight: `${1}rem` }}
                      >
                        <h2 className="text-md font-semibold mb-2">
                          Dağıtım {dataIndex + 1} ({data.ids.length})
                        </h2>
                        <ul className="text-xs">
                          {data.ids.map((personId, personIndex) => (
                            <li key={personIndex}>{personId}</li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {
              <div
                id="map"
                className="bg-white rounded-xl shadow-xl flex flex-col gap-4 m-3 w-full max-lg:w-full"
              >
                <MapContainer
                  attributionControl={false}
                  className="rounded-xl"
                  center={[37.87244919634651, 32.49206387333341]}
                  zoom={11}
                  scrollWheelZoom={true}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                  />
                  {mergedData &&
                    mergedData.map((cluster, clusterIndex) => {
                      const customIcon = (clusterIndex) => {
                        const iconUrl = require(
                          `../../../assets/images/icon-${clusterIndex + 1}.svg`,
                        );
                        return L.icon({
                          iconUrl: iconUrl,
                          iconSize: [30, 30],
                          iconAnchor: [15, 30],
                        });
                      };

                      return cluster.customers.map(
                        (customer, customerIndex) => (
                          <Marker
                            key={`${clusterIndex}-${customerIndex}`}
                            position={[
                              customer.address.lat,
                              customer.address.lon,
                            ]}
                            icon={customIcon(clusterIndex)} // Doğru ikonu burada belirtin
                          >
                            <Popup>
                              {'Personel : ' + customer.id}
                              <br />
                              {`Koordiant :${customer.address.lat},${customer.address.lon}`}
                              <br />
                              {`Servis numarası: ${clusterIndex + 1}`}
                              <br />
                              <br />
                              <Autocomplete
                                id="combo-box-demo"
                                options={
                                  vehicleSize && vehicleSize > 0
                                    ? Array.from(
                                        { length: vehicleSize },
                                        (_, index) => vehicleSize - index,
                                      )
                                    : []
                                }
                                fullWidth
                                size="small"
                                onChange={(event, value) => {
                                  handleChangeService(customer.id, value);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Servis seçiniz"
                                  />
                                )}
                              />
                            </Popup>
                          </Marker>
                        ),
                      );
                    })}
                </MapContainer>
              </div>
            }
            <div className="bg-white rounded-xl shadow-xl flex flex-col gap-4 m-3 w-full max-lg:w-full">
              <Button
                onClick={onSaveDistribution}
                variant="outlined"
                size="large"
                fullWidth
              >
                DAĞITIMI KAYDET
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Users;
