import httpHelper from '../../../../utils/httpHelper';
import { appConfig } from '../../../../constants/appConfig';

const service = {
  async getDistribution(payload) {
    const endPoint = `/distribution/distrubition`;
    const result = await httpHelper.makeGetRequest(
      appConfig.baseUrl,
      endPoint,
      payload,
      // 'Bearer',
    );
    return result;
  },
  async saveTransportationSchema(payload) {
    const endPoint = `/transportation/saveTransportationSchema`;
    const result = await httpHelper.makePostRequest(
      appConfig.baseUrl,
      endPoint,
      payload,
      // 'Bearer',
    );
    return result;
  },
  async getAllShifts(payload) {
    const endPoint = `/shift/findAll`;
    const result = await httpHelper.makeGetRequest(
      appConfig.baseUrl,
      endPoint,
      payload,
      // 'Bearer',
    );
    return result;
  },
};

export default service;
 


