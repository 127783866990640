export const columns = [
  {
    field: 'userIdentity',
    headerName: 'No',
    width: 50,
    renderCell: (data) => {
      return <span>{data.row.userIdentity}</span>;
    },
  },
  {
    field: 'userType',
    headerName: 'Kullanıcı Tipi',
    width: 150,
    renderCell: (data) => {
      return (
        <span>{data.row.userType == 'employee' ? 'Personel' : 'Tanımsız'}</span>
      );
    },
  },
  {
    field: 'userName',
    headerName: 'Personel Adı',
    width: 140,
    renderCell: (data) => {
      return <span>{data.row.userName}</span>;
    },
  },
  {
    field: 'userSurname',
    headerName: 'Personel Soyadı',
    width: 140,
    renderCell: (data) => {
      return <span>{data.row.userSurname}</span>;
    },
  },
  {
    field: 'userAddress',
    headerName: 'Personel Adresi',
    width: 240,
    renderCell: (data) => {
      <span>
        {data.row.userAddress.length <= 20
          ? data.row.userAddress
          : data.row.userAddress.slice(0, 20) + '...'}
      </span>;
    },
  },
  {
    field: 'userShiftId',
    headerName: 'Vardiya',
    width: 120,
    renderCell: (data) => {
      return (
        <span>
          {data.row.userShiftId == '0'
            ? 'İZİNLİ'
            : data.row.userShiftId == '1'
              ? '08:00-16:00'
              : data.row.userShiftId == '2'
                ? '16:00-24:00'
                : data.row.userShiftId == '3'
                  ? '24:00-08:00'
                  : data.row.userShiftId == '4'
                    ? '08:00-18:00'
                    : 'Tanımsız'}
        </span>
      );
    },
  },
  { 
    field: 'userShiftType',
    headerName: 'Vardiyalı',
    width: 90,
    renderCell: (data) => {
      return <span>{data.row.userShiftType ? 'Evet' : 'Hayır'}</span>;
    },
  },
  /* {
    field: 'actions',
    headerName: 'İşlem',
    width: 60,
    sortable: false,
    renderCell: (data) => {
      const onClick = () => {
        const parsedData = data.row.taskRoute);
        const distance = parsedData.paths[0].distance / 1000;
        const points = parsedData.paths[0].points.coordinates;
        const selectedTaskdata = data.row.taskData);
        data.handleClickTask(distance, points, selectedTaskdata);
      };

      return (
        <div className="flex justify-center items-center">
          <IconButton onClick={onClick} href={`#map`}>
            <LocationOnIcon />
          </IconButton>
        </div>
      );
    },
  },*/
];

export const filterList = [
  { value: 'id', text: 'Kullanıcı ID' },
  { value: 'firstname', text: 'İsim' },
  { value: 'lastname', text: 'Soyisim' },
  { value: 'email', text: 'Email' },
  { value: 'gsm', text: 'Telefon' },
];
