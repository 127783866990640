import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Pagination, CircularProgress, Button } from '@mui/material';
import TDataGrid from '../../../../components/Datagrid/Datagrid';
import '../styles.css';
import MapIcon from '@mui/icons-material/Map';
import { Autocomplete, TextField } from '@mui/material';

import 'moment/locale/tr'; // Import the Turkish locale

// import { CMapContainer } from '../../../components/mapContainer/index';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from 'react-leaflet';

import { columns } from '../constants/table_columns_data';
import * as DEFAULT from '../constants/list.default';
import service from '../service';
import { blue } from '@mui/material/colors';
import { set } from 'date-fns';
import { ro } from 'date-fns/locale';
import { toast } from 'react-toastify';

const Routehistory = () => {
  const location = useLocation();

  const [filterData, setFilterData] = useState({ query: '' });
  const [sortModel, setSortModel] = useState(DEFAULT.SORT_MODEL);
  const [isLoading, setIsLoading] = useState(DEFAULT.IS_LOADING);
  const [page, setPage] = useState(DEFAULT.PAGE);
  const [routes, setRoutes] = useState(DEFAULT.ROUTES);
  const [shifts, setShifts] = useState(DEFAULT.SHIFTS);
  const [count, setCount] = useState(DEFAULT.COUNT);
  const [vehicleId, setVehicleId] = useState(DEFAULT.VEHICLEID);
  const [shiftId, setShiftId] = useState(DEFAULT.SHIFTID);
  const [schema, setSchema] = useState(DEFAULT.SCHEMA);
  const [points, setPoints] = useState(DEFAULT.POINTS);
  const [markers, setMarkers] = useState(DEFAULT.MARKERS);
  const [startCoordinates, setStartCoordinates] = useState(
    DEFAULT.STARTCOORDINATES,
  );
  const [distance, setDistance] = useState(DEFAULT.DISTANCE);
  const [duration, setDuration] = useState(DEFAULT.DURATION);

  // content.map((item, index) => {
  //   console.log(JSON.parse(item.taskData));
  // })

  const paginationChange = (_, e_page) => setPage(e_page - 1);
  // İki JSON verisini birleştirme fonksiyonu
  const onSelectRoute = async (selectedRoute) => {
    const data = selectedRoute || [];
    const routingResultData = JSON.parse(data.routingResultData);
    setDistance(routingResultData.solution.distance);
    setDuration(routingResultData.solution.service_duration);
    const emlpoyeeData = JSON.parse(data.routingRequestData);
    const serviceMarkers = emlpoyeeData?.services;
    const employeeDataVehicles = emlpoyeeData?.vehicles[0];
    setMarkers(serviceMarkers);
    setStartCoordinates(employeeDataVehicles.start_address);
    const points = routingResultData?.solution?.routes[0].points;
    setPoints(points);
  };
  const getData = async () => {
    setIsLoading(true);
    try {
      const { field, sort } =
        sortModel.length > 0 ? sortModel[0] : { field: 'taskId', sort: 'desc' };
      const payload = {
        // ...filterData,
        size: 10,
        page: page,
        // sort: `${field},${sort}`,
      };
      const result = await service.getRouteHistory(payload);
      const data = result?.data?.content;
      setRoutes(data ? data : []);
      setIsLoading(false);
    } catch (error) {
      toast.error('Bir hata oluştu');
    }
  };

  const handleFilterInput = (newValue) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      query: newValue,
    }));
  };

  useEffect(() => {
    setPage(0);
    getData();
  }, [filterData]);

  const filter = async () => {
    setPage(0);
    getData();
  };

  useEffect(() => {
    getData();
  }, [page, sortModel, location]);

  return (
    <div className="p-3 flex flex-col justify-center items-center">
      <div className="w-max max-lg:w-full p-3">
        <div className=" py-3 px-2 mx-3 flex flex-wrap justify-between max-sm:justify-center gap-3 items-center">
          <span className="text-lg sm:text-2xl max-sm:text-center">
            Rotalar
          </span>
          {/* <div className="mx-2 my-5 flex justify-between items-center">
            <div className="flex ">
              <TextField
                size="small"
                placeholder="Arama"
                onChange={(e) => handleFilterInput(e.target.value)}
                value={filterData.query}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MATERIEL_ICONS.Search />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                  },
                }}
              />
            </div>
          </div> */}
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center w-[890px]">
            <CircularProgress className="users-list-circular-progress" />
          </div>
        ) : (
          <>
            <div className="bg-white rounded-xl shadow-xl p-3 m-3 w-[984px] max-lg:w-full">
              <TDataGrid
                rows={routes}
                columns={columns}
                sortModel={sortModel}
                onSelectRoute={onSelectRoute}
                setSortModel={setSortModel}
                checkBoxSelection={true}
              />

              <div className="flex justify-center items-center p-3 m-3">
                <Pagination
                  page={page + 1}
                  onChange={paginationChange}
                  count={count}
                  color="primary"
                  size="small"
                />
              </div>
            </div>

            {distance !== 0 && duration !== 0 && (
              <div className="bg-white rounded-xl flex flex-wrap  gap-4 justify-center  lg:justify-around shadow-xl p-3 m-3 w-[984px] max-lg:w-full">
                <span>
                  {'  Tahmini Mesafe : ' + (distance / 1000).toFixed(2) + ' km'}
                </span>
                <span>{'  Tahmini Süre : ' + duration / 60 + ' dakika'}</span>
              </div>
            )}
            {
              <div
                id="map"
                className="bg-white rounded-xl shadow-xl flex flex-col gap-4 p-3 m-3 w-[984px] max-lg:w-full"
              >
                <MapContainer
                  attributionControl={false}
                  center={[37.941863444539074, 32.54820654710296]}
                  zoom={12}
                  scrollWheelZoom={true}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                  />
                  {points &&
                    points.map((item, index) => (
                      <Polyline
                        key={index}
                        pathOptions={{ color: 'blue' }}
                        positions={item.coordinates.map((coord) => [
                          coord[1],
                          coord[0],
                        ])}
                      />
                    ))}
                  {startCoordinates &&
                    startCoordinates.lat &&
                    startCoordinates.lon && (
                      <Marker
                        icon={L.icon({
                          iconUrl:
                            'https://cdn2.iconfinder.com/data/icons/IconsLandVistaMapMarkersIconsDemo/256/MapMarker_Marker_Outside_Chartreuse.png',
                          iconSize: [30, 30],
                          iconAnchor: [15, 30],
                        })}
                        position={[startCoordinates.lat, startCoordinates.lon]}
                      >
                        <Popup>
                          <span className={`font-inter text-gray-500`}>
                            Başlangıç Noktası
                          </span>
                        </Popup>
                      </Marker>
                    )}

                  {markers &&
                    markers.map((location, locationIndex) => {
                      const { lat, lon, location_id } = location.address;

                      return (
                        <Marker
                          icon={L.icon({
                            iconUrl:
                              'https://cdn2.iconfinder.com/data/icons/IconsLandVistaMapMarkersIconsDemo/256/MapMarker_Marker_Outside_Azure.png',
                            iconSize: [30, 30],
                            iconAnchor: [15, 30],
                          })}
                          key={locationIndex}
                          position={[lat, lon]}
                        >
                          <Popup>
                            <span className={`font-inter text-gray-500`}>
                              PERSONEL: {location_id}
                            </span>
                          </Popup>
                        </Marker>
                      );
                    })}
                </MapContainer>
              </div>
            }
          </>
        )}
      </div>
    </div>
  );
};

export default Routehistory;
