import React, { useEffect, useState } from 'react';
import { DashboardContainer } from '../../components/dashboard';
import Employee from '../../assets/images/products_employee.svg';
import Route from '../../assets/images/products_routes.svg';
import Shifts from '../../assets/images/products_shifts.svg';
import EventContainer from '../../components/Events/EventContainer';
import { useBoundStore } from '../../stores/index';
import { Modal, Box, Typography } from '@mui/material';
import { appConfig } from '../../constants/appConfig';
import { websocket_config } from '../../constants/wsConfig';
import logoSmall from '../../assets/images/logoSmall.svg';
import { toast } from 'react-toastify';
import FullLayout from '../../layouts/fullLayout';
import instances from '../../constants/instances';

const productsData = [
  {
    title: 'Personeller',
    image: Employee,
    to: '/users/employees',
  },
  {
    title: 'Vardiyalar',
    image: Shifts,
    to: '/users/shifts',
  },
  {
    title: 'Rotalar',
    image: Route,
    to: '/users/routes/newroute',
  },

];

const Products = () => {
  const modalOpen = JSON.parse(localStorage.getItem('modalOpen'));
  const setModalOpen = useBoundStore((state) => state.setModalOpen);
  const setAppConfig = useBoundStore((state) => state.setAppConfig);
  const setWsConfig = useBoundStore((state) => state.setWsConfig);

  const handleInstanceSelection = (selectedInstance) => {
    setAppConfig(appConfig[selectedInstance.config]);
    setWsConfig(websocket_config.url[selectedInstance.config]);
    localStorage.setItem('modalOpen', 'false');
    localStorage.setItem(
      'selectedInstance',
      JSON.stringify([selectedInstance]),
    );
    setModalOpen(false);
    toast.success(
      `Uygulamaya yönlendiriliyorsunuz. (${selectedInstance.name})`,
    );
    window.location.reload();
  };

  return (
    <FullLayout>
      <Typography variant="h4" gutterBottom sx={{ paddingX: '1.25rem' }}>
        {`Merhaba, ${JSON.parse(localStorage.getItem('user')).user.id}!`}
      </Typography>
      <DashboardContainer data={productsData} />
      <Modal open={modalOpen}>
        <Box
          style={{
            outline: 'none',
            position: 'absolute',
            top: '50%',
            left: '50%',
            borderRadius: 30,
            transform: 'translate(-50%, -50%)',
            width: 500,
            minHeight: 300,
            backgroundColor: 'white',
            boxShadow: 24,
            padding: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h5"
            color="initial"
            gutterBottom
            textAlign={'center'}
          >
            Hangi uygulamaya giriş yapmak istiyorsunuz?
          </Typography>

          <div className="flex flex-wrap w-full justify-center items-center mb-3">
            {instances.map((instance, index) => (
              <div
                key={index}
                className="flex flex-col w-36 justify-center items-center p-2 m-5 cursor-pointer hover:scale-110 transition ease 1s drop-shadow-2xl shadow-lg rounded-lg"
                onClick={() => handleInstanceSelection(instance)}
              >
                <span className="font-semibold m-5">{instance.name}</span>
              </div>
            ))}
          </div>
          <div className="flex justify-end items-center">
            <img className="mr-5 w-24" src={logoSmall} alt="Yerlem" />
          </div>
        </Box>
      </Modal>
    </FullLayout>
  );
};

export default Products;
