export const columns = [
  {
    field: 'number',
    headerName: 'No',
    width: 100,
    renderCell: (data) => {
      return <span>{data.row.number}</span>;
    },
  },
  {
    field: 'name',
    headerName: 'Personel Adı',
    width: 180,
    renderCell: (data) => {
      return <span>{data.row.name}</span>;
    },
  },
  {
    field: 'surname',
    headerName: 'Personel Soyadı',
    width: 180,
    renderCell: (data) => {
      return <span>{data.row.surname}</span>;
    },
  },
  {
    field: 'address',
    headerName: 'Personel Adresi',
    width: 280,
    renderCell: (data) => {
      return <span>{data.row.address}</span>;
    },
  },
  {
    field: 'schema',
    headerName: 'Şeması',
    width: 80,
    renderCell: (data) => {
      return <span>{data.row.schema}</span>;
    },
  },
];

export const filterList = [
  { value: 'id', text: 'Kullanıcı ID' },
  { value: 'firstname', text: 'İsim' },
  { value: 'lastname', text: 'Soyisim' },
  { value: 'email', text: 'Email' },
  { value: 'gsm', text: 'Telefon' },
];
