import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { authService } from '../services/authServices';
import { auth } from '../../../utils/auth';
import cookie from '../../../utils/cookie';
import useAuthStore from '../../../stores/auth-store';
import { toast } from 'react-toastify';
import { FiEye, FiEyeOff, FiLock, FiUser } from 'react-icons/fi';
import { IconButton } from '@mui/material';
import { InputAdornment, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {
  KIMLIK_PASSWORD,
  KIMLIK_USERNAME,
} from '../../../constants/environmentVariables';
import { useBoundStore } from '../../../stores/index';
import { appConfig } from '../../../constants/appConfig';
import { websocket_config } from '../../../constants/wsConfig';

const Login = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState('password');

  const setModalOpen = useBoundStore((state) => state.setModalOpen);
  const setAppConfig = useBoundStore((state) => state.setAppConfig);
  const setWsConfig = useBoundStore((state) => state.setWsConfig);

  const { setIsLoggedIn, isLoggedIn, setUser, setPermissions } = useAuthStore();

  const checkExpiredAt = () => {
    const t1 = new Date();
    const t2 = new Date(parseInt(cookie.getCookie('expiresAt')));
    const dif = t2.getTime() - t1.getTime();

    if (dif < 2) {
      toast.error('Saatinizi kontrol ediniz');
      auth.logOut();
    }

    setTimeout(() => {
      toast.error('Token süreniz dolmuştur');
      auth.logOut();
      navigate('/');
    }, dif);
  };

  async function loginMerchant(data) {
    setIsLoading(true);
    const payload = {
      username: data.userName,
      password: data.password,
    };

    // const result = await authService.token(payload);
    // const token = result?.data?.data?.token;
    // const expiresAt = result?.data?.data?.expiresAt;
    cookie.setCookie('Bearer', `Bearer ${'token'}`, 'expiresAt', true);
    cookie.setCookie('expiresAt', 'expiresAt');

    if (data.userName === 'alpla' && data.password === 'alpla') {
      setIsLoggedIn(true);
      setUser({ user: { id: 'alpla' } });
      localStorage.setItem(
        'user',
        JSON.stringify({ user: { id: 'alpla' } }),
      );
      setPermissions(['*']);
      localStorage.setItem('permissions', JSON.stringify(['*']));
      navigate('/');
    } else {
      toast.error('Böyle bir kullanıcı bulunamadı');
      cookie.removeCookie('Bearer');
      cookie.removeCookie('expiresAt');
    }

    // const tokenForInstance = await authService.getTokenForInstance({
    //   username: KIMLIK_USERNAME,
    //   password: KIMLIK_PASSWORD,
    // });

    // const instanceToken = tokenForInstance?.data?.data?.token;
    // cookie.setCookie(
    //   'instanceToken',
    //   `kimlik ${instanceToken}`,
    //   expiresAt,
    //   true,
    // );
    // cookie.setCookie('instanceExpiresAt', expiresAt);

    // if (token && instanceToken) {
    //   const resInstance = await authService.getInstanceIdByYerlemId({
    //     yid: payload.username,
    //   });
    //   const instance = resInstance?.data?.data;

    //   const check = (i) => {
    //     if (i === 'amir-al0') {
    //       setAppConfig(appConfig.algida);
    //       setWsConfig(websocket_config.url.algida);
    //       return 'http://78.186.223.18:5001/api/v1.1';
    //     } else if (i === 'amir-tr0') {
    //       setAppConfig(appConfig.ayd);
    //       setWsConfig(websocket_config.url.ayd);
    //       return 'http://78.186.223.18:7079/api/v1.1';
    //     }
    //   };
    //   const func = check(instance[0].instance.id);

    //   if (instance.length > 1) {
    //     setModalOpen(true);
    //     localStorage.setItem('modalOpen', 'true');
    //     localStorage.setItem('instance', JSON.stringify(instance));
    //   } else {
    //     localStorage.setItem('instance', JSON.stringify(instance));
    //   }

    //   const result_user = await authService.login(func, payload);
    //   console.log(result_user);
    //   const user = result_user?.data?.data;

    //   const resObserver = await authService.isObserver(func, user?.user?.id);
    //   const observer = resObserver?.data?.data || false;
    //   if (observer) localStorage.setItem('observer', JSON.stringify(observer));

    //   console.log(user);

    //   const result_permissions = observer
    //     ? await authService.getPermissionByRoleId(func, 'TE_OBSERVER')
    //     : await authService.getRBAC(func, user?.user?.id);
    //   const permissions = result_permissions || [];

    //   console.log(permissions);

    //   if (user && permissions?.length !== 0) {
    //     const user_string = JSON.stringify(user);
    //     const permissions_string = JSON.stringify(permissions);
    //     localStorage.setItem('user', user_string);
    //     setUser(user);
    //     localStorage.setItem('permissions', permissions_string);
    //     setPermissions(permissions);
    //     checkExpiredAt();
    //     setIsLoggedIn(true);
    //     navigate('/');
    //     window.location.reload();
    //   } else {
    //     toast.error('Böyle bir kullanıcı bulunamadı');
    //     cookie.removeCookie('Bearer');
    //     cookie.removeCookie('instanceToken');
    //   }
    // } else {
    //   toast.error('Böyle bir kullanıcı bulunamadı');
    //   cookie.removeCookie('Bearer');
    //   cookie.removeCookie('instanceToken');
    // }
    setIsLoading(false);
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    loginMerchant(data);
  };
  return (
    <>
      {isLoading ? (
        <>
          <span className="flex justify-center items-center m-2 p-2 font-bold">
            Initializing. . .
          </span>
          <CircularProgress />
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-5">
              <TextField
                label="Kullanıcı Adı"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FiUser />
                    </InputAdornment>
                  ),
                }}
                {...register('userName', { required: true })}
                type="text"
                placeholder="Kullanıcı Adı"
                size="small"
                required
              />
              <TextField
                label="Şifre"
                size="small"
                type={type}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FiLock />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setType((prev) =>
                            prev === 'password' ? 'text' : 'password',
                          )
                        }
                      >
                        {type === 'password' ? (
                          <FiEye className="text-lg" />
                        ) : (
                          <FiEyeOff className="text-lg" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register('password', { required: true })}
                placeholder="Şifre"
                required
              />
              <button
                type="submit"
                className="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Giriş
              </button>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default Login;
