import React, { useState, useRef } from 'react';
import { Button, CircularProgress } from '@mui/material';
import * as XLSX from 'xlsx';
import { columns } from './constants/upload_table_columns_data';
import { toast } from 'react-toastify';
import TDataGrid from '../../../components/Datagrid/Datagrid';
import service from './service';

const uploadEmployee = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sortModel, setSortModel] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileUpload = () => {
    try {
      setIsLoading(true);
      fileInputRef.current.click();
      setIsLoading(false);
    } catch (error) {
      console.error('Dosya yükleme sırasında bir hata oluştu:', error);
    }
  };
  const handleFileChange = (e) => {
    try {
      setIsLoading(true);
      const file = e.target.files[0];
      if (file && file.name.endsWith('.xlsx')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileContent = e.target.result;
          const workbook = XLSX.read(fileContent, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const range = XLSX.utils.decode_range(sheet['!ref']);
          const totalRows = range.e.r;
          const users = [];
          for (let rowIndex = 1; rowIndex <= totalRows; rowIndex++) {
            try {
              const number =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 0 })];
              const name = sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 1 })];
              const surname =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 2 })];
              const address =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 3 })];
              const coordinates =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 4 })];
              const schema =
                sheet[XLSX.utils.encode_cell({ r: rowIndex, c: 5 })];
              if (number && name && surname && address && schema) {
                users.push({
                  number: number.v,
                  name: name.v,
                  surname: surname.v,
                  address: address.v,
                  coordinates: coordinates ? coordinates.v : '',
                  schema: schema.v,
                });
              }
            } catch (error) {
              console.error('Dosya içeriği okunurken bir hata oluştu:', error);
            }
          }
          setExcelData(users);
          setIsLoading(false);
        };
        reader.onprogress = (event) => {
          if (event.lengthComputable) {
            // İlerleme takibi yapabilirsiniz
            const progress = (event.loaded / event.total) * 100;
            console.log('Yükleme ilerlemesi:', progress);
          }
        };
        reader.readAsBinaryString(file);
      }
    } catch (error) {
      console.error('Dosya yükleme sırasında bir hata oluştu:', error);
      setIsLoading(false);
    }
  };
  const handleSaveUsers = async () => {
    try {
      setIsLoading(true);
      for (let i = 0; i < excelData.length; i++) {
        const user = excelData[i];
        const payload = {
          userType: 'employee',
          userIdentity: user.number,
          userName: user.name,
          userSurname: user.surname,
          userAddress: user.address,
          userCoordinate: user.coordinates,
          userCoordinate2: user.coordinates,
          userShiftType: true,
          userShiftId: user.schema,
          userActive: true,
          userOnline: true,
        };
        try {
          const response = await service.saveUser(payload);
          const status = response?.status;
          if (status === 200) {
            console.log('Kullanıcı kaydedildi:', response);
          } else {
            console.error('Kullanıcı kaydedilemedi:', response);
          }
        } catch (error) {
          console.error('Kullanıcı kaydedilirken bir hata oluştu:', error);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Kullanıcılar kaydedilirken bir hata oluştu:', error);
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="max-lg:w-full px-2 md:px-16">
        <div className="px-2 flex flex-wrap justify-between max-sm:justify-center gap-3 items-center">
          <span className="text-lg font-[Inter] sm:text-2xl max-sm:text-center">
            Dağıtımlar
          </span>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center w-[890px]">
            <CircularProgress className="users-list-circular-progress" />
          </div>
        ) : (
          <>
            <div className="bg-white rounded-md shadow-xl m-3 w-full max-lg:w-full">
              <div className="flex items-start justify-center lg:justify-between p-3 w-full flex-col gap-4">
                <div className="flex flex-row w-full justify-between flex-wrap gap-4">
                  <Button
                    variant="outlined"
                    className="w-1/4"
                    color="primary"
                    onClick={handleFileUpload}
                  >
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept=".xlsx"
                      style={{ display: 'none' }} // Dosya seçme inputunu gizler
                      onChange={handleFileChange}
                    />
                    EXCELİ YÜKLE
                  </Button>
                  <Button
                    variant="outlined"
                    className="w-1/4"
                    color="primary"
                    onClick={handleSaveUsers}
                  >
                    KAYDET
                  </Button>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-md shadow-xl m-3 w-full max-lg:w-full">
              <TDataGrid
                rows={excelData}
                columns={columns}
                sortModel={sortModel}
                setSortModel={setSortModel}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default uploadEmployee;
